import useHelpers from '~/composables/useHelpers'
import Component = models.stores.sitecoreLayoutPage.Component
import ComponentFields = models.stores.sitecoreLayoutPage.ComponentFields
import Item = models.stores.sitecoreLayoutPage.Item
import ComponentBinding = models.stores.sitecoreLayoutPage.ComponentBinding

export default function () {
  const { stringEmpty } = useHelpers()

  interface ComponentMap {
    [key: string]: {
      isValid: (fields: ComponentFields | undefined) => boolean
      value: (
        fields: ComponentFields | undefined,
        shared: Record<string, unknown>
      ) => ComponentBinding
    }
  }

  const require = (
    fields: ComponentFields | Item[] | undefined,
    val: string | string[]
  ): boolean => {
    if (!fields) return false

    if (Array.isArray(fields)) {
      return fields.every((f) => require(f.fields, val))
    } else if (Array.isArray(val)) {
      return val.every((v) => fields[v])
    } else {
      return !!fields[val]
    }
  }

  type Link = models.server.api.sitecore.sitecoreLayoutService.LinkValue

  const mapCta = (button?: Link, icon?: string) => {
    let href = button?.href || button?.url || '#'
    if (button?.linktype === 'internal') {
      if (/^\/en\/TAFE-Website\/Home\//i.test(href)) {
        href = href.replace(/^\/en\/TAFE-Website\/Home\//i, '/').toLowerCase()
      }
    }
    return button
      ? {
          text: button.text,
          href: href,
          icon: icon || undefined,
          component: button.linktype === 'internal' ? 'nuxt-link' : 'a',
          linktype: button.linktype
        }
      : undefined
  }

  const componentMap: ComponentMap = {
    accordion: {
      isValid: (fields) =>
        require(fields, ['AccordionItems']) &&
        (<Item[]>fields?.AccordionItems).length > 0 &&
        require(<Item[]>fields?.AccordionItems, ['AccordionTitle']),
      value: (fields, shared) => ({
        type: 'Accordions',
        containerProps: {
          title: fields?.AccordionsSectionHeading,
          colour: shared?.color
        },
        props: {
          color: shared?.color,
          items: (<Item[]>fields?.AccordionItems)?.map((item) => ({
            title: item.fields?.AccordionTitle,
            subtitle: item.fields?.AccordionSubtitle,
            content: item.fields?.AccordionDescription,
            color: shared?.color,
            active: item.fields?.AccordionExpanded || false
          }))
        }
      })
    },
    contentArea: {
      isValid: () => true,
      value: (fields, shared) => ({
        type: 'ContentArea',
        containerProps: {
          title: fields?.ContentAreaTitle,
          subtitle: fields?.ContentAreaSubtitle,
          colour: shared?.color
        },
        lead: fields?.ContentAreaLead as string,
        props: {
          body: fields?.ContentAreaBody,
          color: shared?.color,
          image: safeGet(fields?.ContentAreaImage, (o) => ({
            src: o.src,
            alt: o.alt
          })),
          cta: [
            mapCta(<Link>fields?.ContentAreaCta1Button),
            mapCta(<Link>fields?.ContentAreaCta2Button),
            mapCta(<Link>fields?.ContentAreaCta3Button)
          ].filter(Boolean)
        }
      })
    },
    contentCards: {
      isValid: (fields) =>
        require(fields, ['ContentCardsItems']) &&
        (<Item[]>fields?.ContentCardsItems).length > 0,
      value: (fields, shared) => ({
        type: 'ContentCards',
        containerProps: {
          title: fields?.ContentCardsHeading,
          subtitle: fields?.ContentCardsSubheading,
          colour: shared?.color
        },
        props: {
          items: (<Item[]>fields?.ContentCardsItems)?.map((c) => ({
            title: c.fields?.ContentCardHeading,
            subtitle: c.fields?.ContentCardSubheading,
            content: c.fields?.ContentCardDescription,
            'theme-color': shared?.color,
            image: safeGet(c.fields?.ContentCardImage, (o) => ({
              src: o.src,
              alt: o.alt
            })),
            cta: mapCta(<Link>c.fields?.CtaButton)
          }))
        }
      })
    },
    contentWithImage: {
      isValid: (fields) =>
        require(fields, [
          'ContentWithImageDescription',
          'ContentWithImageImage'
          // @ts-ignore
        ]) && fields?.ContentWithImageImage?.src?.trim().length > 0,
      value: (fields, shared) => ({
        type: 'ContentArea',
        containerProps: {
          title: fields?.ContentWithImageHeading,
          subtitle: fields?.ContentWithImageSubheading,
          colour: shared?.color
        },
        props: {
          image: safeGet(fields?.ContentWithImageImage, (o) => ({
            src: o.src,
            alt: o.alt
          })),
          body: fields?.ContentWithImageDescription,
          color: shared?.color,
          variation: fields?.ContentWithImageVariant,
          cta: [mapCta(<Link>fields?.ContentWithImageCtaButton)].filter(Boolean)
        }
      })
    },
    heroBanner: {
      isValid: (fields) =>
        require(fields, ['HeroBannerImage']) &&
        // @ts-ignore
        fields?.HeroBannerImage?.src?.length > 0,
      value: (fields) => ({
        type: 'HeroBanner',
        props: {
          title: fields?.HeroBannerTitle,
          subtitle: fields?.HeroBannerSubtitle,
          variation: fields?.HeroBannerVariant,
          image: safeGet(fields?.HeroBannerImage, (o) => ({
            src: o.src,
            alt: o.alt
          })),
          ctas: [
            mapCta(
              <Link>fields?.HeroBannerPrimaryButton,
              fields?.HeroBannerPrimaryIcon as string
            ),
            mapCta(
              <Link>fields?.HeroBannerSecondaryButton,
              fields?.HeroBannerSecondaryIcon as string
            )
          ].filter(Boolean) // filter out any undefined values
        }
      })
    },
    table: {
      isValid: (fields) => require(fields, ['TableBody']),
      value: (fields, shared) => ({
        type: 'Table',
        containerProps: {
          title: fields?.TableHeader,
          subtitle: fields?.TableSubHeading,
          colour: shared?.color
        },
        props: {
          tableData: {
            description: fields?.TableDescription,
            body: fields?.TableBody
          }
        }
      })
    },
    testimonial: {
      isValid: (fields) =>
        require(fields, ['TestimonialItems']) &&
        (<Item[]>fields?.TestimonialItems).length > 0,
      value: (fields, shared) => ({
        type: 'TestimonialCards',
        containerProps: {
          title: fields?.TestimonialItemsHeading,
          colour: shared?.color
        },
        props: {
          testimonials:
            (<Item[]>fields?.TestimonialItems)?.map((t) => ({
              title: t.fields?.TestimonialItemHeading,
              subtitle: t.fields?.TestimonialItemSubheading,
              content: t.fields?.TestimonialItemDescription,
              image: safeGet(t.fields?.TestimonialItemImage, (o) => ({
                src: o.src,
                alt: o.alt
              }))
            })) || []
        }
      })
    },
    video: {
      isValid: (fields) =>
        require(fields, ['VideoUrl']) &&
        (fields?.VideoUrl as string)?.length > 0,
      value: (fields, shared) => ({
        type: 'YoutubeVideo',
        containerProps: {
          title: fields?.VideoHeading,
          subtitle: fields?.VideoSubheading,
          colour: shared?.color
        },
        props: {
          src: fields?.VideoUrl,
          description: fields?.VideoDescription
        }
      })
    },
    partnerLogos: {
      isValid: (fields) =>
        require(fields, ['PartnerLogosList']) &&
        (<Item[]>fields?.PartnerLogosList).length > 0,
      value: (fields, shared) => ({
        type: 'PartnerLogos',
        containerProps: {
          title: fields?.PartnerLogosTitle,
          colour: shared?.color
        },
        props: {
          title: fields?.PartnerLogosTitle,
          partners: (<Item[]>fields?.PartnerLogosList)?.map((partner) => ({
            img: safeGet(partner.fields?.PartnerLogoImage, (o) => ({
              src: o.src,
              alt: o.alt
            })),
            name: partner.fields?.PartnerName,
            url: (<Link>partner.fields?.PartnerUrl)?.url,
            external:
              (<Link>partner.fields?.PartnerUrl)?.linktype === 'external'
          }))
        }
      })
    }
  }

  const validMaps = Object.keys(componentMap)

  const safeGet = <T = Record<string, unknown>>(
    obj: unknown | undefined,
    get: (obj: T) => unknown
  ) => (obj ? get(obj as T) : undefined)

  const mapComponent = (
    component: Component,
    shared: Record<string, unknown>
  ): ComponentBinding | undefined => {
    const { componentName } = component

    if (
      !componentMap ||
      stringEmpty(componentName) ||
      !validMaps.includes(<string>componentName) ||
      !component?.fields
    ) {
      return undefined
    }

    const isValid = componentMap[componentName as keyof ComponentMap].isValid(
      component.fields
    )

    if (!isValid) return undefined

    const value = componentMap[componentName as keyof ComponentMap].value(
      component.fields,
      shared
    )

    return {
      ...value
    } as ComponentBinding
  }

  const mapComponents = (
    components: Component[] | undefined,
    shared: Record<string, unknown> | undefined
  ): ComponentBinding[] => {
    if (!components?.length) return []

    return components
      .map((component) => mapComponent(component, shared || {}))
      .filter(Boolean) as ComponentBinding[]
  }

  return { mapComponents }
}
